import { VisibilityGodclass } from "./visibility_godclass"

// VisibilityController functionality is defined
// in VisibilityGodclass and this class created only because
// `data-controller="visibility"` does not work unless controller
// is defined via `export >>>default<<< class extends`

// all of this has been done to be able to slightly extend
// VisibilityController for AutomationReport form in
// AutomationFormVisibilityController :facepalm:
export default class extends VisibilityGodclass {}
