import Dropzone from "dropzone";
import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import {
  getMetaValue,
  findElement,
  removeElement,
  insertAsTheLastElementOf
} from "helpers";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      // this hack fights weird dropzone behavior where it "uploads" the file
      // twice if you add a file ontop of an existing one
      let howManyFilesDropzoneAllows = Number(this.dropZone.options.maxFiles || 0)
      let howManyFilesDropzoneHolds = this.dropZone.files.length
      if (howManyFilesDropzoneHolds > howManyFilesDropzoneAllows) { return true; }

      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("removedfile", file => {
      removeElement(document.querySelector(`input[data-name="${file.name}"][data-size="${file.size}"]`));

      if (file.controller) {
        file.controller.emitCustomEventOnWindow('dropzoneWasClearedUp');
        file.controller.hiddenInput.remove();
      }
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        this.hiddenInput.remove();
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAsTheLastElementOf(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
    this.emitCustomEventOnWindow('dropzoneFileSuccessfullyAttached');
  }

  emitCustomEventOnWindow(name) {
    const event = new CustomEvent(name);

    window.dispatchEvent(event);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function changeRemoveLinkToIcon(file){
  findElement(
      file.previewTemplate,
      ".dz-remove"
  ).innerHTML = '';

  findElement(
      file.previewTemplate,
      ".dz-remove"
  ).classList.add('pt-1.5');
}

function generateIcon(iconClass) {
  return `<div class='w-full h-full flex justify-center items-center'><div class='icon text-6xl ${iconClass}'></div></div>`
}

function setIcon(file, extensionName, fileType) {
  let ext = file.name.split('.').pop();

  if (extensionName == ext) {
    findElement(
      file.previewTemplate,
      ".dz-image",
    ).innerHTML = generateIcon(fileType);
  }
}

function changeUploadedFileIcon(file) {
  setIcon(file, "xml", "icon-file");
  setIcon(file, "json", "icon-file");
  setIcon(file, "html", "icon-file");
  setIcon(file, "csv", "icon-file-csv");
  setIcon(file, "pdf", "icon-file-pdf");
  setIcon(file, "mp4", "icon-file-video");
  setIcon(file, "zip", "icon-file");
}

function addCustomFile(dropZone){
  document.querySelectorAll('div.mx-5.attachment.vertical').forEach(element => {
    const thumbnail_url = element.querySelector("a.attachment-thumb img").src;
    const file = {
      processing: true,
      accepted: true,
      name: element.querySelector('a.attachment-name').innerHTML,
      size: element.querySelector("a.attachment-thumb").dataset.size,
      status: Dropzone.SUCCESS
    };
    dropZone.files.push(file);
    dropZone.emit("addedfile", file);
    if (thumbnail_url) {
      dropZone.emit("thumbnail", file, thumbnail_url);
    }
    else {
      dropZone.emit("thumbnail", file);
      changeUploadedFileIcon(file);
    }
    dropZone.emit("processing", file);
    dropZone.emit("success", file, { status: "success" } , false);
    dropZone.emit("complete", file);
    removeElement(element);
    changeRemoveLinkToIcon(file);
  });
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: true,
    autoQueue: false,
    dictFileTooBig: "File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.",
    maxFiles: controller.maxFiles,
    init: function() {
      addCustomFile(this);
      this.on("addedfile", changeRemoveLinkToIcon);
      this.on("complete", changeRemoveLinkToIcon);
      this.on("complete", changeUploadedFileIcon);
      this.on("maxfilesexceeded", function(file) {
        this.removeAllFiles();
        this.addFile(file);
      });
    }
  });
}
