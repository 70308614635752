import { Controller } from "@hotwired/stimulus"
import { basicSetup, EditorView } from "codemirror"
import { oneDark, oneDarkTheme, oneDarkHighlightStyle } from "@codemirror/theme-one-dark"
import { keymap } from "@codemirror/view"
import { java } from "@codemirror/lang-java"

export default class extends Controller {
  static targets = ["code", "chat", "reload", "spinner", "error", "dialogMessage", "icon", "prediction", "loadingIndicator"]
  static values = {
    fetchPredictionFollowupPath: String,
    reloadPredictionPath: String
  }

  dialogMessageTargetConnected(t) {
    let view;

    view = new EditorView({doc: t.value.trim(), extensions: [ basicSetup, EditorView.editable.of(false), java(), EditorView.lineWrapping ]})

    t.parentNode.insertBefore(view.dom, t)
    t.style.display = "none"
  }

  predictionTargetConnected() {
    this.hideSpinner();
    this.showActions();
    this.hideLoadingIndicator();
  }

  errorTargetConnected() {
    this.hideSpinner();
  }

  chatKeymap(keys, target = this.codeTarget) {
    return keymap.of([
      { key: keys, run() { target.dispatchEvent(new Event('submitChatMessage')); return true }
    }])
  }

  codeTargetConnected() {
    this.hideSpinner();
    this.showActions();
    this.hideLoadingIndicator();

    this.token = document.querySelector(
      'meta[name="csrf-token"]'
    ).content;

    this.view = new EditorView({
      doc: this.codeTarget.value.trim(),
      extensions: [ basicSetup, EditorView.lineWrapping, oneDark, java(), this.chatKeymap('Shift-Enter') ]
    })

    this.codeTarget.parentNode.insertBefore(this.view.dom, this.codeTarget)
    this.codeTarget.style.display = "none"

    this.codeTarget.addEventListener('submitChatMessage', this.magic.bind(this))
  }

  magic() {
    this.showSpinner();
    this.hideActions();
    this.showLoadingIndicator();

    fetch(
      this.fetchPredictionFollowupPathValue, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': this.token,
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin',
        body: JSON.stringify({
          message: this.view.state.doc.toString()
        })
      }
    )
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html));
  }

  reload() {
    this.showSpinner()
    this.hideActions()
    this.showLoadingIndicator()

    fetch(this.reloadPredictionPathValue)
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html));
  }

  copy({ params: { code } }) {
    let icon = event.currentTarget;

    setTimeout(() => {
      icon.classList.toggle("icon-copy");
      icon.classList.toggle("icon-check");
    }, 500);

    if (code) {
      code = code.trim();
    } else {
      code = this.view.state.doc.toString();
    }

    navigator.clipboard.writeText(code);

    icon.classList.toggle("icon-copy");
    icon.classList.toggle("icon-check");
  }

  hideActions() {
    this.reloadTarget.style.display = "none"
    this.chatTarget.style.display = "none"
  }

  showActions() {
    this.reloadTarget.style.display = "inline-flex"
    this.chatTarget.style.display = "inline-flex"
  }

  showSpinner() {
    this.spinnerTarget.style.display = "grid"
  }

  hideSpinner() {
    this.spinnerTarget.style.display = "none"
  }

  showLoadingIndicator() {
    this.loadingIndicatorTarget.style.display = "inline-block"
  }

  hideLoadingIndicator() {
    this.loadingIndicatorTarget.style.display = "none"
  }
}
