import { VisibilityGodclass } from "./visibility_godclass"
import { removeElement, insertAfter} from "helpers";

export default class extends VisibilityGodclass {
  toggleExtensionForm(event) {
    const pressed_button = event.currentTarget;
    const next_button = pressed_button.previousElementSibling || pressed_button.nextElementSibling;

    if (pressed_button.classList.contains('selected')) {
      this.unselectButton(pressed_button)
      super.toggle(event)
    }
    else if (next_button.classList.contains('selected')) {
      this.unselectButton(next_button)
      this.selectButton(pressed_button)
    }
    else {
      this.selectButton(pressed_button)
      super.toggle(event)
    }
  }

  hideExtensionForm(event) {
    this.cleanUpForm(event)
    document.querySelectorAll('label.extend-for').forEach(element => {
      this.unselectButton(element)
    })
    super.toggle(event)
  }

  unselectButton(button) {
    button.classList.remove('btn-primary')
    button.classList.remove('selected')
    button.classList.add('btn-secondary')
    this.removeHiddenInput(button)
  }

  selectButton(button) {
    button.classList.remove('btn-secondary')
    button.classList.add('selected')
    button.classList.add('btn-primary')
    this.createHiddenInput(button.id, button.parentNode)
  }

  cleanUpForm(event) {
    event.target.closest('form').elements.namedItem('task_reservation_extension[comment]').value = ''
    event.target.closest('form').querySelectorAll('input.radio-button.extension-reason').forEach(element => {
      element.checked = false
    });
    this.removeHiddenInput(event.target)
    this.removeMessage('error_explanation')
    this.removeMessage('notice_explanation')
  }

  createHiddenInput(value, insertAfterElement) {
    const extended_for_cd = this.splitValue(value)
    const input = document.createElement("input")
    input.type = "hidden"
    input.value = extended_for_cd
    input.name = "task_reservation_extension[extended_for_cd]"
    insertAfter(input, insertAfterElement)
    return input
  }

  removeHiddenInput(element) {
    const hidden_input = element.closest('form').elements.namedItem('task_reservation_extension[extended_for_cd]')
    hidden_input && removeElement(hidden_input)
  }

  splitValue(value) {
    return value.split("-").pop()
  }

  removeMessage(id) {
    const el = document.getElementById(id);
    el && removeElement(el)
  }
}
