import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  static targets = [ "field" ]

  connect() {
    this.hiddenClass = this.data.get("class") || "hidden"
  }

  no(event) {
    this.fieldTarget.classList.add(this.hiddenClass)
    this.fieldTarget.querySelectorAll('input[type=password]')[0].value = ''
  }

  yes(event) {
    this.fieldTarget.classList.remove(this.hiddenClass)
  }
}
