import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["counter", "counterText", "navBar", "updateButton", "updateIcon"]

  connect() {
    this.main = document.querySelector('main')

    this.countCheckboxes()
  }

  countCheckboxes() {
    var checkboxesInputs = document.getElementsByTagName("input"),
    counter = 0;

    for (var i=0; i<checkboxesInputs.length; i++) {
      if (checkboxesInputs[i].type === "checkbox" && checkboxesInputs[i].checked === true) {
        counter++;
      }
    }

    if(counter > 0) {
      this.counterTarget.innerHTML = counter;

      if (counter == 1) {
        this.counterTextTarget.innerHTML = "task <br> selected";
      }
      else {
        this.counterTextTarget.innerHTML = "tasks <br> selected";
      }

      this.showNavBar();
      this.showCounter();
      this.showActionbar();
    }
    else {
      this.hideNavBar();
      this.hideCounter();
      this.hideActionbar();
    }

    this.modifyUpdateButton(counter);
  }

  showCounter() {
    if (this.hasCounterTarget) {
      this.counterTarget.classList.remove("hidden");
      this.counterTextTarget.classList.remove("hidden");
    }
  }

  hideCounter() {
    if (this.hasCounterTarget) {
      this.counterTarget.classList.add("hidden");
      this.counterTextTarget.classList.add("hidden");
    }
  }

  showNavBar() {
    if (this.hasNavBarTarget) {
      this.navBarTarget.classList.remove("hidden");
    }
  }

  hideNavBar() {
    if (this.hasNavBarTarget) {
      this.navBarTarget.classList.add("hidden");
    }
  }

  hideActionbar() {
    this.main.classList.remove('floating-actionbar');
  }

  showActionbar() {
    this.main.classList.add('floating-actionbar');
  }

  modifyUpdateButton(counter) {
    if(this.hasUpdateButtonTarget) {
      if (counter == 0) {
        this.updateButtonTarget.innerHTML = "Delete group";
        this.updateIconTarget.classList.remove("icon-check");
        this.updateIconTarget.classList.add("icon-remove");
      } else {
        this.updateButtonTarget.innerHTML = "Update group";
        this.updateIconTarget.classList.remove("icon-remove");
        this.updateIconTarget.classList.add("icon-check");
      }
    }
  }
}
