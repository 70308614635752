import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["counter", "counterText", "prepButtons"]

  connect() {
    this.processCheckboxes()
  }

  counterTargetConnected() {
    this.processCheckboxes()
  }

  get checkboxes() {
    return document.querySelectorAll('[id*="test_case_row"]:not([disabled])')
  }

  processCheckboxes() {
    let counter = 0;

    this.checkboxes.forEach((checkbox) => {
      if (checkbox.type === "checkbox" && checkbox.checked === true) {
        counter++
      }
    })

    this.counterTarget.innerHTML = counter;

    if (counter == 1) {
      this.counterTextTarget.innerHTML = "Test case <br> selected for import";
    } else {
      this.counterTextTarget.innerHTML = "Test cases <br> selected for import";
    }

    if (counter > 0) {
      this.showPrepButtons();
    } else {
      this.hidePrepButtons();
    }
  }

  showPrepButtons() {
    if (this.hasPrepButtonsTarget) {
      this.prepButtonsTarget.classList.remove("hidden");
    }
  }

  hidePrepButtons() {
    if (this.hasPrepButtonsTarget) {
      this.prepButtonsTarget.classList.add("hidden");
    }
  }
}
