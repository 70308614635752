import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    setInterval(() => { this.fetchTasks() }, 5000);
  }

  fetchTasks() {
    fetch('/tasks.json?only_json=true')
    .then( data => {
      return data.json();
    }).then(tasks => {
      if (tasks.length > 0)
        window.location.href = '/tasks';
    });
  }
}
