import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "select", "option" ]

  connect() {
    this.selectedClass = this.data.get("class") || "selected";
    this.input = this.inputTargets[0];
    this.select = this.selectTargets[0];
    this.inputValues = this.input.value != '' ? this.input.value.split(',') : [];
  }

  toggle(event) {
    event.currentTarget.classList.toggle(this.selectedClass);

    let currentTargetId = event.currentTarget.id;

    if (event.currentTarget.classList.contains(this.selectedClass)) {
      let span = document.createElement("span");
      span.id = currentTargetId;
      span.classList.add("tag", "removable", "mr-1", "mb-1");
      span.innerHTML = event.currentTarget.innerHTML + "<div class='icon icon-cross text-icon-xs'></div>";
      span.setAttribute("data-action", "click->multiselect#remove");
      this.select.appendChild(span);

      this.inputValues.push(currentTargetId);
      this.input.value = this.inputValues.join(',');
    } else {
      this.select.querySelector(`#${currentTargetId}`).remove();

      this.remove_from_input(currentTargetId);
    }
  }

  remove(event) {
    let currentTargetId = event.currentTarget.id;

    this.optionTargets.filter(item => item.id == currentTargetId)[0].classList.remove(this.selectedClass);
    event.currentTarget.remove();

    this.remove_from_input(currentTargetId);
  }

  remove_from_input(value) {
    let inputElement = this.inputValues.indexOf(value);
    this.inputValues.splice(inputElement, 1);

    this.input.value = this.inputValues.join(',');
  }
}
