import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitButton"];

  connect() {
    window.addEventListener("dropzoneFileSuccessfullyAttached", () => {
      this.submitButtonTarget.removeAttribute('disabled');
    });

    window.addEventListener("dropzoneWasClearedUp", () => {
      this.submitButtonTarget.setAttribute('disabled', true);
    });
  }
}
