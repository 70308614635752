import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  static targets = [ "dynamic" ]

  connect() {
    this.hiddenClass = this.data.get("class") || "hidden"
  }

  toggle(event) {
    const actor = event.target.getAttribute('id');
    this.dynamicTargets.forEach(target => {
      if (target.getAttribute('data-lite-visibility-actor') === actor)
        target.classList.remove(this.hiddenClass)
      else
        target.classList.add(this.hiddenClass)
    })
  }
}
