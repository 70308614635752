import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "irrelevant", "relevant" ]

  connect() {
    this.hiddenClass = this.data.get("class") || "hidden"
  }

  showFileUpload(event) {
    if (event.target.value === 'CsvImportConnection') {
      this.hide(this.irrelevantTargets)
      this.show(this.relevantTargets)
    } else {
      this.show(this.irrelevantTargets)
      this.hide(this.relevantTargets)
    }
  }

  hide(targets) {
    targets.forEach(target => {
      target.classList.add(this.hiddenClass)
    })
  }

  show(targets) {
    targets.forEach(target => {
      target.classList.remove(this.hiddenClass)
    })
  }
}
