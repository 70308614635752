// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// enable Stimulus debug mode in development
application.debug = process.env.NODE_ENV === 'development'

import { Toggle, Dropdown, Modal, Tabs, Popover, Slideover } from "tailwindcss-stimulus-components"
application.register('toggle', Toggle)
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('slideover', Slideover)

import Trix from "trix"
// attachment class is already reserved by our design-system
Trix.config.css.attachment = 'trix-attachment';

window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg']
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("We only support images as attachments")
  }

  const maxFileSize = 10 * 1024 * 1024 // 10MB
  if (event.file.size > maxFileSize) {
    event.preventDefault()
    alert("We only support attachment files upto size 10MB!")
  }
})

Turbo.setConfirmMethod(function (message, element) {
  return new Promise(function (resolve) {
    if (confirm(message)) {
      resolve(true);
    } else {
      window.location.reload();
      resolve(false);
    }
  })
})
