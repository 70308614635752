import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectAll", "selectOne", "showAndSelectAll", "showAll", "spinner"]

  connect() {
    this.selectAllTarget.checked = false
  }

  toggleChildren() {
    if (this.selectAllTarget.checked) {
      this.selectOneTargets.forEach((child) => {
        child.checked = true
      })

      this.mimicContentLoading();

      this.showAndSelectAllTarget.click();
    } else {
      this.selectOneTargets.map(x => x.checked = false)
    }
  }

  toggleParent() {
    if (this.selectOneTargets.map(x => x.checked).includes(false)) {
      this.selectAllTarget.checked = false
    } else {
      this.selectAllTarget.checked = true
    }
  }

  mimicContentLoading() {
    if (this.hasShowAllTarget) {
      this.spinnerTarget.classList.remove("hidden")
      this.showAllTarget.setAttribute("disabled", true)
    }
  }
}
