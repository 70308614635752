import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "block" ]

  connect() {
    this.oneMinuteInterval = 60000;

    if (!this.blockTarget.getAttribute('data-paused'))
      this.incrementTimeTrackerBlockHTML();
  }

  incrementTimeTrackerBlockHTML() {
    setInterval(() => {
      let timeTrackerData = this.blockTarget.innerHTML.trim().split(':');

      let hour = parseInt(timeTrackerData[0]);
      let minute = parseInt(timeTrackerData[1]);

      if (minute === 59) {
        hour++;
        minute = 0;
      } else {
        minute++;
      }

      this.blockTarget.innerHTML = `
        ${hour.toString().length === 1 ? '0' + hour : hour}:${minute.toString().length === 1 ? '0' + minute : minute} h
      `;
    }, this.oneMinuteInterval)
  }
}
