import { VisibilityGodclass } from "./visibility_godclass"

export default class extends VisibilityGodclass {
  static targets = [
    "dynamic",
    "dynamicWithClickOutsideEnabled",
    "curtain",                          // DIV that hides all dynamic fields
    "switch",                           // the switch itself
    "genericLabel",                     // default labels of the Form
    "TestRailConnection",               // all fields of the TestRailConnection
    "inputField",                       // all inputs of the Form
    "authSettingsError"                 // error flash message on top of Auth Settings fields (if any)
  ]

  switchTargetConnected() {
    this.processLabels()
  }

  showTarget(event) {
    if (event.target.value === "") {
      this.curtainTarget.classList.add(this.hiddenClass)
    } else {
      this.curtainTarget.classList.remove(this.hiddenClass)
    }

    this.dynamicTargets.forEach(target => {
      target.classList.add(this.hiddenClass)
    })

    this.processLabels()

    const target = this.dynamicTargets.find(target => target.dataset.id === event.target.value)

    target.classList.remove(this.hiddenClass)

    // all inputs of the Form
    this.inputFieldTargets.forEach(target => {
      target.value = "";                                          // clear all inputs on the switch
      target.parentElement.classList.remove('field_with_errors'); // remove error CSS from all inputs

      let isThereAnError = target.parentElement.nextElementSibling && target.parentElement.nextElementSibling.classList.contains("error");
      if (isThereAnError) {                                       // remove error message from all inputs
        target.parentElement.nextElementSibling.remove();
      }
      if (this.hasAuthSettingsErrorTarget) {                      // remove error message on top of Auth Settings fields
        this.authSettingsErrorTarget.remove();
      }
    });
  }

  processLabels() {
    switch (this.switchTarget.value) {
      case "TestRailConnection":
        this.genericLabelTargets.forEach(target => { target.classList.add('hidden') });
        this.TestRailConnectionTargets.forEach(target => { target.classList.remove('hidden') });
        break;
      default:
        this.genericLabelTargets.forEach(target => { target.classList.remove('hidden') });
        this.TestRailConnectionTargets.forEach(target => { target.classList.add('hidden') });
    }
  }
}
