import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "projectOwnerCheckbox", "testManagerCheckbox", "coreTeamMemberCheckbox", "customerUserCheckbox" ]

  check_owner(event) {
    this.testManagerCheckboxTargets.forEach(target => {
      if (target.checked) {
        target.checked = false;
      }
    })
    this.customerUserCheckboxTargets.forEach(target => {
      if (target.checked) {
        target.checked = false;
      }
    })
    this.coreTeamMemberCheckboxTargets.forEach(target => {
      if (target.checked) {
        target.checked = false;
      }
    })
  }

  check_customer(event) {
    this.projectOwnerCheckboxTargets.forEach(target => {
      if (target.checked) {
        target.checked = false;
      }
    })
    this.testManagerCheckboxTargets.forEach(target => {
      if (target.checked) {
        target.checked = false;
      }
    })
    this.coreTeamMemberCheckboxTargets.forEach(target => {
      if (target.checked) {
        target.checked = false;
      }
    })
  }

  check_manager(event) {
    this.projectOwnerCheckboxTargets.forEach(target => {
      if (target.checked) {
        target.checked = false;
      }
    })
    this.customerUserCheckboxTargets.forEach(target => {
      if (target.checked) {
        target.checked = false;
      }
    })
    this.coreTeamMemberCheckboxTargets.forEach(target => {
      if (target.checked) {
        target.checked = false;
      }
    })
  }

  check_core_team_member(event) {
    this.projectOwnerCheckboxTargets.forEach(target => {
      if (target.checked) {
        target.checked = false;
      }
    })
    this.customerUserCheckboxTargets.forEach(target => {
      if (target.checked) {
        target.checked = false;
      }
    })
    this.testManagerCheckboxTargets.forEach(target => {
      if (target.checked) {
        target.checked = false;
      }
    })
  }
}
