import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "block", "removable" ]

  blockTargetConnected() {
    this.countdownPattern = new RegExp("[0-9]{1,3}:[0-9]{2}:[0-9]{2}$")
    this.oneMinuteInterval = 1000

    this.decrementTimeTrackerBlockHTML()
  }

  blockTargetDisconnected() {
    clearInterval(this.intervalId)
  }

  decrementTimeTrackerBlockHTML() {
    let countdownBlock = this.blockTarget.innerHTML.trim()

    if (this.countdownPattern.test(countdownBlock) === false) {
      return false
    }

    this.intervalId = setInterval(() => {
      let countdownBlockData = this.blockTarget.innerHTML.trim().split(':')

      let hour = parseInt(countdownBlockData[0])

      if (hour < 24) {
        this.blockTarget.classList.contains("manager") && this.blockTarget.classList.add("text-red-500");
      }

      if (hour < 0) {
        this.onFinishActions()
      }

      let minute = parseInt(countdownBlockData[1])
      let second = parseInt(countdownBlockData[2])

      if (second === 0) {
        minute--
        second = 59
      } else {
        second--
      }

      if (minute < 0) {
        hour--
        minute = 59
      }

      let formattedTime = `${hour.toString().length === 1 ? '0' + hour : hour}:${minute.toString().length === 1 ? '0' + minute : minute}:${second.toString().length === 1 ? '0' + second : second}`

      this.blockTarget.innerHTML = formattedTime

      if (formattedTime === '00:00:00') {
        this.onFinishActions()
      }
    }, this.oneMinuteInterval)
  }

  onFinishActions() {
    if (document.getElementsByClassName('manager').length === 0) {
      this.endReservationOnWorkerInterface()
    } else {
      this.endReservationOnCustomerInterface()
    }
  }

  endReservationOnWorkerInterface() {
    alert('Task Reservation has expired')
    let redirectUrl = this.blockTarget.getAttribute('data-redirect-url')
    this.removableTargets.forEach(target => {
      target.remove()
    })
    if (redirectUrl === null) {
      return false
    }
    window.location.href = redirectUrl
  }

  endReservationOnCustomerInterface() {
    const myBoundMethod = function () {
      let frame = this.element.parentElement
      frame.src = this.element.getElementsByTagName('a')[0].href
    }.bind(this)
    setTimeout(myBoundMethod, 3000) // re-rendering of the task should be done with a small delay so that the reservation on the backend side has time to become inactive
  }
}
