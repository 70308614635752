import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "displayFieldByChange", "hideFieldByChange" ]

  editDurations(event) {
    event.preventDefault();
    event.target.classList.add("hidden");
    this.displayFieldByChangeTargets.forEach(target => target.classList.remove("hidden"));
    this.hideFieldByChangeTargets.forEach(target => target.classList.add("hidden"));
  }

  closeModal(event) {
    event.preventDefault();
    this.element.removeAttribute("open");
  }
}
