import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "taskSessionPerDay", "destroyField", "selectCheckbox", "valueInput" ]

  connect() {
    this.originalDurations = this.durations()
  }

  check() {
    this.destroyFieldTarget.value = !this.selectCheckboxTarget.checked;
  }

  makeReasonForChangeMandatory() {
    if (this.originalDurations !== this.durations()) {
      document.getElementById("task_session_reason_for_change").setAttribute('required', true)
    } else {
      document.getElementById("task_session_reason_for_change").removeAttribute('required')
    }
  }

  durations() {
    const durations = []
    document.getElementsByClassName("duration-input-container").forEach(container => {
      if (!container.classList.contains('hidden'))
        durations.push(container.getElementsByClassName('duration-input')[0].value)
    })
    return durations.join(',')
  }
}
