import { VisibilityGodclass } from "./visibility_godclass"

export default class extends VisibilityGodclass {
  connect() {
    super.connect()
  }

  showHappyPath(event) {
    this.cleanUpComment(event)
    super.show(event)
  }

  showNegativePath(event) {
    this.cleanUpComment(event)
    super.hide(event)
  }

  cleanUpComment(event) {
    event.target.closest('form').elements.namedItem('automate_task_report_comment').value = ''
  }
}
