import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.sidebar = document.querySelector("#workerInfoSidebar")
  }

  close(event) {
    this.sidebar.classList.remove('open')
    this.sidebar.classList.add('hidden')
  }

  open(event) {
    event.preventDefault();
    this.sidebar.classList.add('open')
    this.sidebar.classList.remove('hidden')
  }
}
