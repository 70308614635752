import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hideable", "showable" ]

  apply(event) {
    if (!this.showableTargets[0].classList.contains("hidden")) {
      return false;
    }

    this.hideableTargets.forEach(target => {
      target.classList.add("hidden");
    });

    this.showableTargets.forEach(target => {
      target.classList.remove("hidden");
    });
  }
}
