import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "content", "nestedFrame" ]

  contentTargetConnected() {
    var linkify = require('linkify-it')();
    var content = this.element.innerHTML;

    var matches = linkify.match(content),
        result  = [],
        output,
        last;

    if (matches) {
      last = 0;

      matches.forEach(function (match) {
        if (last < match.index) {
          result.push(content.slice(last, match.index));
        }

        // if the match is inside an href, don't make it a link
        if (content.substring(match.index - 6, match.index - 1) == 'href=') {
          last = match.lastIndex;

          return result.push(match.text);
        }

        result.push('<a target="_blank" class="prose-blue-link" href="');
        result.push(match.url);
        result.push('">');
        result.push(match.text);
        result.push('</a>');
        last = match.lastIndex;
      });

      if (last < content.length) {
        result.push(content.slice(last));
      }

      output = result.join('');
    }

    // it fails sometimes and returns `undefined`
    // which is not what we want to show
    if (output) {
      this.element.innerHTML = output
    } else {
      // at least trigger a reload of the nested turbo-frames
      // for MD attachments
      this.nestedFrameTargets.forEach((frame) => {
        frame.reload();
      });
    }
  }
}
