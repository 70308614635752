import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const maxCharacterCount = 2000;
    const submitButton = document.getElementById('skillsTab').querySelector('.btn-white');
    const error = document.getElementById('trixError');

    addEventListener("trix-change", event => {
      const { editor } = event.target
      const string = editor.getDocument().toString()
      const characterCount = string.length - 1
      if (characterCount > maxCharacterCount) {
        submitButton.disabled = true;
        error.classList.remove('hidden');
      } else {
        submitButton.disabled = false;
        error.classList.add('hidden');
      }
    })
  }
}
