import { Controller } from "@hotwired/stimulus"

import TomSelect from "tom-select"

export default class extends Controller {
  initialize() {
    new TomSelect(this.element, {
      plugins: this.element.getAttribute("data-multiple") === "true" ? [ 'remove_button' ] : []
    });
  }
}
