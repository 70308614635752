import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    window.addEventListener("disableReserveButtons", (event) => {
      for (const projectId of event.detail.projectIds) {
        this.disableReserveButtonsFor(projectId)
      }
    });
  }

  disableReserveButtonsFor(projectId) {
    this.buttonTargets.forEach(target => {
      if (target.getAttribute('data-project-id') === projectId.toString() && target.getAttribute('data-is-reserved') !== 'true') {
        target.setAttribute('disabled', 'disabled')
        target.classList.add('disabled')
      }
    })
  }
}
