import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

export class VisibilityGodclass extends Controller {
  static targets = [ "dynamic", "dynamicWithClickOutsideEnabled" ]

  connect() {
    this.hiddenClass = this.data.get("class") || "hidden"
    useClickOutside(this)
  }

  toggle(event) {
    this.dynamicTargets.forEach(target => {
      target.classList.toggle(this.hiddenClass)
    })
    this.dynamicWithClickOutsideEnabledTargets.forEach(target => {
      target.classList.toggle(this.hiddenClass)
    })
  }

  show(event) {
    this.dynamicTargets.forEach(target => {
      target.classList.remove(this.hiddenClass)
    })
    this.dynamicWithClickOutsideEnabledTargets.forEach(target => {
      target.classList.remove(this.hiddenClass)
    })
  }

  hide(event) {
    this.dynamicTargets.forEach(target => {
      target.classList.add(this.hiddenClass)
    })
    this.dynamicWithClickOutsideEnabledTargets.forEach(target => {
      target.classList.add(this.hiddenClass)
    })
  }

  hideDynamicWithClickOutsideEnabledTargets(event) {
    this.dynamicWithClickOutsideEnabledTargets.forEach(target => {
      target.classList.add(this.hiddenClass)
    })
  }

  showTarget(event) {
    this.dynamicTargets.forEach(target => {
      target.classList.add(this.hiddenClass)
    })

    const target = this.dynamicTargets.find(target => target.dataset.id === event.target.id)

    target.classList.remove(this.hiddenClass)
  }

  clickOutside(event) {
    this.hideDynamicWithClickOutsideEnabledTargets(event)
  }
}
