import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "canBeExecuted", "notExecutable" ]

  connect() {
    if (document.getElementById("verify_task_report_result_cd_0").checked) {
      this.showCanBeExecuted();
    } else if (document.getElementById("verify_task_report_result_cd_1").checked) {
      this.showNotExecutable();
    } else {
      this.showCanBeExecuted();
    }
  }

  showCanBeExecuted() {
    this.canBeExecutedTarget.classList.remove("hidden");
    this.notExecutableTarget.classList.add("hidden");
  }

  showNotExecutable() {
    this.notExecutableTarget.classList.remove("hidden");
    this.canBeExecutedTarget.classList.add("hidden");
  }
}
