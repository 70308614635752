import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "dynamic", "output", "value" ]

  reloadPreview() {
    let src = new URL(this.outputTarget.src);
    let selectedSkills = this.dynamicTargets.map(select => [...select.selectedOptions].map(option => option.value)).flat()

    if (selectedSkills.length === 0) {
      this.valueTarget.value = '0';
      return;
    }

    src.searchParams.delete('skill_ids[]');
    selectedSkills.forEach(skill => src.searchParams.append(`skill_ids[]`, skill));

    this.outputTarget.src = src.toString();

    this.outputTarget.removeAttribute('complete', ''); // it does this.outputTarget.reload() on it's own!!!
  }
}
